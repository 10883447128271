<template>
    <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
        <v-snackbar v-model="showsnackbar" color="black" right>
            <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showsnackbar = false">
                        <v-icon style="color: white">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <v-layout wrap v-if="about" pa-5>
            <v-flex xs6>
                <v-layout wrap>
                    <v-flex xs12 text-left>
                        <span style="color: #000; font-family: poppinsbold; font-size: 25px">About Us</span>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs6 text-right py-5>
                <v-dialog :retain-focus="false" v-model="dialog" persistent max-width="800px" :key="dialog">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark color="green" v-bind="attrs" v-on="on">
                            UPDATE
                        </v-btn>
                    </template>
                    <v-card>
                        <v-form>
                            <v-card-title>
                                <span class="headline">Updates Details</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense v-model="about.title
                                                " label="Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <span>Description</span>
                                        </v-col>
                                        <v-col cols="12">
                                            <div id="app">
                                                <vue-editor v-model="about.description
                                                    "></vue-editor>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <ImageComp :singleImage="about.banner" @stepper="winStepper"
                                                :heading="'Upload Banner Image'" :componentType="'aboutBannerImage'" />
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense v-model="about.aboutFounder.title
                                                " label="About Founder Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <span>About Founder Description</span>
                                        </v-col>
                                        <v-col cols="12">
                                            <div id="app">
                                                <vue-editor v-model="about.aboutFounder.description
                                                    "></vue-editor>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <ImageComp :singleImage="about.aboutFounder.image" @stepper="winStepper"
                                                :heading="'Upload Founder Image'" :componentType="'aboutFounderImage'" />
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense v-model="about.expertise.title
                                                " label="Expertise Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <span>Expertise Description</span>
                                        </v-col>
                                        <v-col cols="12">
                                            <div id="app">
                                                <vue-editor v-model="about.expertise.description
                                                    "></vue-editor>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <ImageComp :singleImage="about.expertise.image" @stepper="winStepper"
                                                :heading="'Upload Expertise Image'" :componentType="'aboutExpertiseImage'" />
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense v-model="about.globalReach.title
                                                " label="Global Reach Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <span>Global Reach Description</span>
                                        </v-col>
                                        <v-col cols="12">
                                            <div id="app">
                                                <vue-editor v-model="about.globalReach.description
                                                    "></vue-editor>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <ImageComp :singleImage="about.globalReach.image" @stepper="winStepper"
                                                :heading="'Upload Global Reach Image'" :componentType="'aboutGlobalReachImage'" />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">
                                    Close
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="update()">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
            </v-flex>
            <v-flex xs12 sm6 md4>
                <v-img :src="mediaURL + about.banner" contain height="200px"></v-img>
            </v-flex>
            <v-flex xs12 sm6 md8 pa-2 align-self-center v-if="about.title">
                <span style="font-family: poppinsbold; font-size: 16px">
                    {{ about.title }}
                    <br />
                    <br />
                    <span style="
                font-family: poppinsregular;
                font-size: 14px;
                text-align: justify;
              " v-html="about.description
                  "></span>
                </span>
            </v-flex>
            <v-layout wrap pa-5>
                <v-flex xs12>
                    <span style="font-family: poppinsbold; font-size: 18px">
                        FOUNDER
                        <br />
                        <br />
                    </span>
                </v-flex>
                <v-flex xs12 sm6 md8 align-self-center v-if="about.aboutFounder">
                    <span style="font-family: poppinsbold; font-size: 16px">
                        {{ about.aboutFounder.title }}
                        <br />
                        <br />
                        <span style="
                font-family: poppinsregular;
                font-size: 14px;
                text-align: justify;
              " v-html="about.aboutFounder.description
                  "></span>
                    </span>
                </v-flex>
                <v-flex xs12 sm6 md4 pa-2>
                    <v-img contain height="500px" :src="mediaURL + about.aboutFounder.image"></v-img>
                </v-flex>
            </v-layout>
            <v-layout wrap pa-5>
                <v-flex xs12>
                    <span style="font-family: poppinsbold; font-size: 18px">
                        EXPERTISE
                        <br />
                        <br />
                    </span>
                </v-flex>
                <v-flex xs12 sm6 md4>
                    <v-img :src="mediaURL + about.expertise.image"></v-img>
                </v-flex>
                <v-flex xs12 sm6 md8 pa-2 align-self-center v-if="about.expertise">
                    <span style="font-family: poppinsbold; font-size: 16px">
                        {{ about.expertise.title }}
                        <br />
                        <br />
                        <span style="
                font-family: poppinsregular;
                font-size: 14px;
                text-align: justify;
              " v-html="about.expertise.description
                  "></span>
                    </span>
                </v-flex>
            </v-layout>
            <v-layout wrap pa-5>
                <v-flex xs12>
                    <span style="font-family: poppinsbold; font-size: 18px">
                        GLOBAL REACH
                        <br />
                        <br />
                    </span>
                </v-flex>
                <v-flex xs12 sm6 md8 pa-2 align-self-center v-if="about.globalReach">
                    <span style="font-family: poppinsbold; font-size: 16px">
                        {{ about.globalReach.title }}
                        <br />
                        <br />
                        <span style="
                font-family: poppinsregular;
                font-size: 14px;
                text-align: justify;
              " v-html="about.globalReach.description
                  "></span>
                    </span>
                </v-flex>
                <v-flex xs12 sm6 md4>
                    <v-img :src="mediaURL + about.globalReach.image"></v-img>
                </v-flex>
            </v-layout>
        </v-layout>
    </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import ImageComp from "@/components/Common/singleImages";
export default {
    components: {
        VueEditor,
        ImageComp,
    },
    data() {
        return {
            showsnackbar: false,
            ServerError: false,
            msg: null,
            appLoading: false,
            dialog: false,
            itemid: "",
            aboutBannerImage: "",
            aboutFounderImage: "",
            aboutExpertiseImage:"",
            aboutGlobalReachImage:"",
            about: [],
            itemediting: [],
            rules: {
                required: (value) => !!value || "Required.",
                min: (v) => v.length >= 8 || "Min 8 characters",
            },
        };
    },
    mounted() {
        this.getData()
    },
    methods: {
        winStepper(window_data) {
            if (window_data.type == "aboutBannerImage") {
                this.aboutBannerImage = window_data.selectedFiles;
            }
            if (window_data.type == "aboutFounderImage") {
                this.aboutFounderImage = window_data.selectedFiles;
            }
            if (window_data.type == "aboutExpertiseImage") {
                this.aboutExpertiseImage = window_data.selectedFiles;
            }
            if (window_data.type == "aboutGlobalReachImage") {
                this.aboutGlobalReachImage = window_data.selectedFiles;
            }
        },
        getData() {
            this.appLoading = true;
            axios({
                url: "/aboutUs/admin/view",
                method: "GET",
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    this.about = response.data.data;
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        update() {
            var data = {};
            data["title"] = this.about.title;
            data["description"] = this.about.description;
            data["aboutFounder"] = this.about.aboutFounder;
            data["expertise"] = this.about.expertise;
            data["globalReach"] = this.about.globalReach;
            axios({
                url: "/aboutUs/edit",
                method: "POST",
                data: data,
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status) {
                        if (this.aboutBannerImage) {
                            this.uploadBannerImage();
                        }
                        if (this.aboutFounderImage) {
                            this.uploadFounderImage();
                        }
                        if (this.aboutExpertiseImage) {
                            this.uploadExpertiseImage();
                        }
                        if (this.aboutGlobalReachImage) {
                            this.uploadGlobalReachImage();
                        }
                        this.msg = "Edited Sucessfully";
                        this.showsnackbar = true;
                        this.dialog = false;
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        uploadBannerImage() {
            let formData = new FormData();
            formData.append("photo", this.aboutBannerImage);
            axios({
                method: "POST",
                url: "/about/upload/banner/image",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                        this.getData();
                        this.showsnackbar = true;
                        this.msg = "Uploaded Successfully";
                        this.dialog = false;
                    } else {
                        this.msg = "Can't Update";
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        uploadFounderImage() {
            let formData = new FormData();
            formData.append("photo", this.aboutFounderImage);
            axios({
                method: "POST",
                url: "/about/upload/aboutFounder/image",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                        this.getData();
                        this.showsnackbar = true;
                        this.msg = "Uploaded Successfully";
                        this.dialog = false;
                    } else {
                        this.msg = "Can't Update";
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        uploadExpertiseImage() {
            let formData = new FormData();
            formData.append("photo", this.aboutExpertiseImage);
            axios({
                method: "POST",
                url: "/about/upload/expertise/image",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                        this.getData();
                        this.showsnackbar = true;
                        this.msg = "Uploaded Successfully";
                        this.dialog = false;
                    } else {
                        this.msg = "Can't Update";
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        uploadGlobalReachImage() {
            let formData = new FormData();
            formData.append("photo", this.aboutGlobalReachImage);
            axios({
                method: "POST",
                url: "/about/upload/globalReach/image",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                        this.getData();
                        this.showsnackbar = true;
                        this.msg = "Uploaded Successfully";
                        this.dialog = false;
                    } else {
                        this.msg = "Can't Update";
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>
<style scoped>
.item_title {
    font-weight: bold;
    font-family: poppinssemibold;
}
</style>
  