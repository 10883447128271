var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"spinner":"bar-fade-scale","color":"black","size":"128","is-full-screen":""}}),_c('v-snackbar',{attrs:{"color":"black","right":""},model:{value:(_vm.showsnackbar),callback:function ($$v) {_vm.showsnackbar=$$v},expression:"showsnackbar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showsnackbar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1),(_vm.about)?_c('v-layout',{attrs:{"wrap":"","pa-5":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"color":"#000","font-family":"poppinsbold","font-size":"25px"}},[_vm._v("About Us")])])],1)],1),_c('v-flex',{attrs:{"xs6":"","text-right":"","py-5":""}},[_c('v-dialog',{key:_vm.dialog,attrs:{"retain-focus":false,"persistent":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"green"}},'v-btn',attrs,false),on),[_vm._v(" UPDATE ")])]}}],null,false,4098189252),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Updates Details")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Title","required":""},model:{value:(_vm.about.title
                                            ),callback:function ($$v) {_vm.$set(_vm.about, "title", $$v)},expression:"about.title\n                                            "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Description")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"app"}},[_c('vue-editor',{model:{value:(_vm.about.description
                                                ),callback:function ($$v) {_vm.$set(_vm.about, "description", $$v)},expression:"about.description\n                                                "}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('ImageComp',{attrs:{"singleImage":_vm.about.banner,"heading":'Upload Banner Image',"componentType":'aboutBannerImage'},on:{"stepper":_vm.winStepper}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"About Founder Title","required":""},model:{value:(_vm.about.aboutFounder.title
                                            ),callback:function ($$v) {_vm.$set(_vm.about.aboutFounder, "title", $$v)},expression:"about.aboutFounder.title\n                                            "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("About Founder Description")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"app"}},[_c('vue-editor',{model:{value:(_vm.about.aboutFounder.description
                                                ),callback:function ($$v) {_vm.$set(_vm.about.aboutFounder, "description", $$v)},expression:"about.aboutFounder.description\n                                                "}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('ImageComp',{attrs:{"singleImage":_vm.about.aboutFounder.image,"heading":'Upload Founder Image',"componentType":'aboutFounderImage'},on:{"stepper":_vm.winStepper}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Expertise Title","required":""},model:{value:(_vm.about.expertise.title
                                            ),callback:function ($$v) {_vm.$set(_vm.about.expertise, "title", $$v)},expression:"about.expertise.title\n                                            "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Expertise Description")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"app"}},[_c('vue-editor',{model:{value:(_vm.about.expertise.description
                                                ),callback:function ($$v) {_vm.$set(_vm.about.expertise, "description", $$v)},expression:"about.expertise.description\n                                                "}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('ImageComp',{attrs:{"singleImage":_vm.about.expertise.image,"heading":'Upload Expertise Image',"componentType":'aboutExpertiseImage'},on:{"stepper":_vm.winStepper}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Global Reach Title","required":""},model:{value:(_vm.about.globalReach.title
                                            ),callback:function ($$v) {_vm.$set(_vm.about.globalReach, "title", $$v)},expression:"about.globalReach.title\n                                            "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Global Reach Description")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"app"}},[_c('vue-editor',{model:{value:(_vm.about.globalReach.description
                                                ),callback:function ($$v) {_vm.$set(_vm.about.globalReach, "description", $$v)},expression:"about.globalReach.description\n                                                "}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('ImageComp',{attrs:{"singleImage":_vm.about.globalReach.image,"heading":'Upload Global Reach Image',"componentType":'aboutGlobalReachImage'},on:{"stepper":_vm.winStepper}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.about.banner,"contain":"","height":"200px"}})],1),(_vm.about.title)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md8":"","pa-2":"","align-self-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.about.title)+" "),_c('br'),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"14px","text-align":"justify"},domProps:{"innerHTML":_vm._s(_vm.about.description
              )}})])]):_vm._e(),_c('v-layout',{attrs:{"wrap":"","pa-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"18px"}},[_vm._v(" FOUNDER "),_c('br'),_c('br')])]),(_vm.about.aboutFounder)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md8":"","align-self-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.about.aboutFounder.title)+" "),_c('br'),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"14px","text-align":"justify"},domProps:{"innerHTML":_vm._s(_vm.about.aboutFounder.description
              )}})])]):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","pa-2":""}},[_c('v-img',{attrs:{"contain":"","height":"500px","src":_vm.mediaURL + _vm.about.aboutFounder.image}})],1)],1),_c('v-layout',{attrs:{"wrap":"","pa-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"18px"}},[_vm._v(" EXPERTISE "),_c('br'),_c('br')])]),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.about.expertise.image}})],1),(_vm.about.expertise)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md8":"","pa-2":"","align-self-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.about.expertise.title)+" "),_c('br'),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"14px","text-align":"justify"},domProps:{"innerHTML":_vm._s(_vm.about.expertise.description
              )}})])]):_vm._e()],1),_c('v-layout',{attrs:{"wrap":"","pa-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"18px"}},[_vm._v(" GLOBAL REACH "),_c('br'),_c('br')])]),(_vm.about.globalReach)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md8":"","pa-2":"","align-self-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.about.globalReach.title)+" "),_c('br'),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"14px","text-align":"justify"},domProps:{"innerHTML":_vm._s(_vm.about.globalReach.description
              )}})])]):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.about.globalReach.image}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }